.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f5f5f5;
}

.card {
  box-shadow: none;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.cardContent {
  padding-bottom: 16px !important;
}

.postContainer {
  margin-bottom: 16px;
  border: 1px solid #b3b3b3;
  padding: 20px;
  position: relative;
}

.postHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}

.postContent {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.postInfo {
  margin-top: 8px;
  line-height: 1.6;
  font-size: 16px;
  color: #333;

  ul,
  ol {
    list-style: none;
    padding-left: 0;
  }

  li {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 6px;
    font-weight: 300;
  }

  strong {
    font-weight: 300;
    min-width: 250px;
    display: inline-block;
    color: #636262;
  }

  a {
    color: #1976d2;
    text-decoration: none;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 12px;
    color: #222;
    margin-bottom: 12px;
  }
}

.timestamp {
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.dateText {
  color: #b3b3b3;
  font-size: 12px;
}

.authorText {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.dataTypeText {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.imageSize {
  width: 32px;
  height: 32px;
}

.postContainerNonMarkdown {
  @extend .postContainer;
}

.postHeaderNonMarkdown {
  @extend .postHeader;
}

.postContentNonMarkdown {
  @extend .postContent;
}

.timestampNonMarkdown {
  @extend .timestamp;
}
